.login-component {
  display: flex;
  justify-content: space-between;
  height: 100vh;

  .left-section,
  .right-section {
    flex: 1;
    padding: 1rem;
  }

  .left-section {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 3rem;
    width: 50%;

    .kriah-title {
      font-weight: 900;
      font-size: 4rem;
      color: #2fa0e2 !important;
      font-family: "Montserrat", sans-serif;
    }

    .img {
      height: 40%;
      width: auto;
    }
  }

  .right-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;

  }

  // Media queries for responsiveness
  @media (max-width: 1200px) {

    // Laptop view
    .kriah-title {
      font-size: 3rem;
    }

    .left-section {
      .img {
        height: 30%;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 820px) {
    // Tablet view
    height: 0vh;
    flex-direction: column;

    .left-section,
    .right-section {
      flex: unset;
      width: 100%;
      padding: 1rem;
      align-items: center;
      height: 65vh;
      width: auto;
    }

    .kriah-title {
      font-size: 2.5rem;
    }

    .left-section {
      .img {
        height: 43%;
      }
    }
  }

  @media (max-width: 480px) {
    // Mobile view
    flex-direction: column;
    height: auto; // Changed to auto to fit content

    .left-section,
    .right-section {
      flex: unset;
      padding: 0.5rem; // Reduced padding
      align-items: center;
      justify-content: start;
      height: 65vh; // Set to auto for better layout
      gap: 1rem; // Adjust gap for mobile view
      width: auto;
    }

    .right-section {
      margin-top: -25vh;
    }

    .kriah-title {
      font-size: 2rem;
    }

    .left-section {
      .img {
        height: 40%; // Maintain size for better visibility
      }
    }
  }

  @media (max-width: 767px) {
    // Mobile view
    flex-direction: column;
    height: auto; // Changed to auto to fit content

    .left-section,
    .right-section {
      flex: unset;
      padding: 0.5rem; // Reduced padding
      align-items: center;
      justify-content: start;
      height: 65vh; // Set to auto for better layout
      gap: 1rem; // Adjust gap for mobile view
      width: auto;
    }

    .right-section {
      margin-top: -25vh;
    }

    .kriah-title {
      font-size: 2rem;
    }

    .left-section {
      .img {
        height: 40%; // Maintain size for better visibility
      }
    }
  }
}