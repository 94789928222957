#customized-dialog-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f3ff;
  padding: 0.5rem 1rem;
  font-weight: 600;
  color: #42526d;
}

.close-icon {
  padding: 0.25rem;
}

.save-button {
  display: flex;
  justify-content: center;
}

.MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root {
  padding-top: 1rem !important;
}

.MuiDialogTitle-root+.css-1ty026z {
  padding-top: 1rem !important;
}