.signin-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  align-items: center;
  padding: 1.7rem 0;

  .signin-label {
    color: #2fa0e2 !important;
    font-size: 2rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.75rem 0;
  }

  .signin-form {
    width: 20rem;
    padding: 2rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;

    .signin-button {
      margin-top: 1rem;
      background-color: #2fa0e2 !important;
    }

    .signup-button {
      display: flex;
      justify-content: center;
      color: #fa9847;
      font-weight: 600;
      margin: 0;
      cursor: pointer;
    }
  }
}

@media (max-width: 480px) {
  .signin-component {
    width: 100%;

    .signin-form {
      padding: 22px;
    }
  }
}