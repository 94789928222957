.custom-button-class-blue {
  white-space: nowrap;
  text-transform: unset !important;
  font-size: 20px !important;
  color: #ffffff !important;
  background-color: #2fa0e2 !important;
}

.custom-button-class-org {
  white-space: nowrap;
  text-transform: unset !important;
  font-size: 20px !important;
  color: #e1220d !important;
  background-color: #fea959 !important;
}

.custom-button-class-blue-primary {
  white-space: nowrap;
  text-transform: unset !important;
  color: #ffffff !important;
  background-color: #5364ff;
  font-size: 20px !important;
}

.custom-button-class-red {
  white-space: nowrap;
  text-transform: unset !important;
  color: #ffffff !important;
  background-color: #ff5353 !important;
  font-size: 20px !important;
}

.font-style {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}