.app-content {
  margin-top: 4.063rem;
  padding-left: 15.625rem;
  height: calc(100vh - 3rem);
}

.studentDashBoard {
  margin-top: 4.063rem;
}

@media (max-width: 820px) {
  .app-content {
    padding-left: 0;
    margin-top: 3.438rem;
    height: 100vh;
  }
}

.css-ivnu1y-MuiPaper-root-MuiAppBar-root {
  background-color: #2fa0e2 !important;
}

.css-17xcwyf {
  background-color: #2fa0e2 !important;
}

body {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

.token {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-right: 2rem;

  .token-label {
    margin: 0;
    font-weight: 700;
  }
}

.MuiFormControlLabel-label {
  font-family: 'Frank Ruhl Libre', regular !important;
  font-size: 40px !important;
}

// .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label.level-10-or-more {
//   font-family: "Mekorot Rashi", sans-serif;
//   font-size: 40px;
// }

@font-face {
  font-family: "Mekorot Regular";
  src: url('../../src/assets/font/Mekorot-Regular.ttf') format('truetype');
}

.font-style-10 span {
  font-family: "Mekorot Regular" !important;
  font-size: 40px;
}