.signup-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  align-items: center;
  padding: 1.5rem 0;

  .signup-label {
    font-size: 2rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.75rem 0;
    color: #2fa0e2 !important;
  }

  .signup-form {
    width: 20rem;
    padding: 2rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;

    .sing-up-button {
      margin-top: 1rem;
    }

    .already-button {
      display: flex;
      justify-content: center;
      color: #9fabb5;
      font-weight: 600;
      margin: 0;

      .signin-label {
        color: #2fa0e2 !important;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 480px) {
  .signup-component {
    width: 100%;

    .signin-form {
      padding: 22px;
    }
  }
}